<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="3" lg="3"></v-col>
      <v-col cols="12" md="6" lg="6">
        <v-card elevation="8" class="pa-6">
          <v-row class="pt-3">
            <v-col cols="12" md="12" lg="12">
              <label class="label-input">{{ $t("e-approve.form.file") }}</label>
              <v-col class="mb-6 d-flex flex-column" cols="12">
                <div class="choose-image">
                  <div class="show-image">
                    <i class="fas fa-file-pdf"></i>
                    <input
                      @change="PreviewPDF"
                      type="file"
                      class="input-file-image"
                    />
                    <!-- <p>File(PDF)</p> -->

                    <embed
                      class="embed-pdf"
                      :src="editData.file_name"
                      type="application/pdf"
                      accept="application/pdf"
                    />
                    <img :src="editData.file_name" alt="" />
                  </div>
                </div>
              </v-col>
            </v-col>

            <v-col cols="12" md="12" lg="12">
              <label class="label-input">{{
                $t("e-approve.form.title")
              }}</label>
              <v-text-field
                v-model="editData.title"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" lg="12">
              <label class="label-input">{{
                $t("e-approve.form.detail")
              }}</label>
              <v-textarea
                label="Detail"
                auto-grow
                outlined
                rows="5"
                row-height="15"
                v-model="editData.description"
              ></v-textarea>
            </v-col>

            <v-col cols="12" md="12" lg="12">
              <label class="label-input">{{
                $t("e-approve.form.approve_by")
              }}</label>
              <v-autocomplete
                v-model="approved_bys"
                :items="listEmployeeApprove"
                outlined
                dense
                chips
                item-value="user_id"
                item-text="name"
                clearable
                deletable-chips
                multiple
                persistent-hint
                requried
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" class="pb-4">
              <v-btn
                class="mt-7 btn-create lfont btn"
                color="info"
                @click="saveChange"
              >
                {{ $t("e-approve.form.btn") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" lg="3"></v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    // editData: {},
  },
  data() {
    return {
      file_type_check: "",
      showImage: "",
      showPDF: "",
      imagePDF: "",

      listEmployeeApprove: [],
      approved_bys: [],
      approved: [],
      file_name: "",
      id: "",
      editData: {
        file_name: "",
        title: "",
        description: "",
        approve_by: "",
        approved_bys: "",
      },
      server_errors: {
        editData: {
          file_name: "",
          title: "",
          description: "",
          approve_by: "",
          approved: [],
        },
      },
    };
  },
  methods: {
    fetchEmployeeApprove() {
      this.isLoading = true;
      this.$axios
        .get(`/company/all/employee-name`)
        .then((res) => {
          //console.log("test", res);
          if (res.status === 200) {
            this.isLoading = false;
            this.listEmployeeApprove = res.data.data;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          //console.log(error);
          this.isLoading = false;
        });
    },

    PreviewPDF: function(event) {
      const input = event.target;
      const file = input.files[0];
      const fileType = file["type"];
      this.file_type_check = file.type;

      const validImageTypes = [
        "application/pdf",
        "image/jpeg",
        "image/png",
        "image/jpg",
      ];
      if (!validImageTypes.includes(fileType)) {
        this.$notification.ShowErrors(
          "top-right",
          "danger",
          3000,
          "image must be PDF"
        );
      } else if (input.files && input.files[0]) {
        this.imagePDF = input.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.editData.file_name = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
        this.UploadPDF(this.imagePDF);
      }
    },

    UploadPDF(imagePDF) {
      let formData = new FormData();
      formData.append("imageFile", imagePDF);
      this.$axios.post(`upload-file`, formData).then((res) => {
        if (res.status === 200) {
          this.file_name = res.data.filename;
        }
      });
    },

    saveChange() {
      let newArray = [];
      for (let i = 0; i < this.approved_bys.length; i++) {
        newArray.push({ id: this.approved_bys[i] });
      }
      console.log(newArray);
      // return;

      const items = {
        file_name: this.file_name,
        title: this.editData.title,
        description: this.editData.description,
        approve_by: null,
        approved: newArray,
      };
      this.$axios
        .put(`/employee/edit/approval/${this.id}`, items)
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.$emit("close");
            this.$emit("success");
            this.$router
              .push({
                name: "employee_e_approve.index",
              })
              .catch(() => {});
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },

    getOldApproveds() {
      this.editData.approved_bys.forEach((item) => {
        this.approved_bys.push(item.user_id);
      });
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.editData = this.$route.params.editData;
    this.fetchEmployeeApprove();
    this.getOldApproveds();
    // this.fetchApprove();
    // console.log(this.editData);
  },
};
</script>

<style lang="scss" scoped></style>
